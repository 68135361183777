import Image from 'next/image'
import { Container } from '@components/Container'
import { AppLayout } from '@components/layouts/AppLayout'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'

export default function Custom404() {
  const { isNightOrDarkTheme } = useTheme()
  return (
    <AppLayout>
      <Container>
        <div className='relative max-w-[500px] mobile:max-w-[350px] h-[500px] mobile:h-[350px] m-auto'>
          <Image
            src={
              isNightOrDarkTheme
                ? '/images/404Error_dark.jpg'
                : '/images/404Error.jpg'
            }
            alt='404Error'
            layout='fill'
            priority
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          />
        </div>
      </Container>
    </AppLayout>
  )
}
